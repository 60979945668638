import { useState } from 'react'
import { Link } from 'react-router-dom'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast } from 'react-toastify';
import Header from '../components/Header';
import Button from '../components/Button';

function ForgotPassword() {
  const [formData, setFormData] = useState({ email: '' })

  const { email } = formData;

  const validEmail = !!email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const showSubmitBtn = validEmail;

  const toastId = 'single-toast';

  function inputChange(e) {
    setFormData(prev => ({...prev, [e.target.id]: e.target.value}))
  }

  async function submitForm(e) {
    e.preventDefault()
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email)
      toast.success('Password reset email sent!')
    } catch (err) {
      toast.error(err.code)
    }
  }

  function onInvalidInput(e) {
    e.preventDefault()
    if (e.target.id === 'email') { toast.error("Escribe un correo electrónico válido.", { toastId }) }
  }
  
  return (
    <div className='pageContainer'>
      <Header text='Forgot Password' />
      <Header text='Enter an email and we will send you a reset password link.' />

      <form onSubmit={submitForm}>
        <input 
          required
          type="email" 
          id='email' 
          className='emailInput' 
          placeholder='Correo electrónico' 
          value={email} 
          onChange={inputChange} 
          onInvalid={onInvalidInput} 
        />
        <Button 
          clickHandler={submitForm}
          isDisabled={!showSubmitBtn}
          text={'Send Link'}
        />
      </form>
      <Link to='/sign-in' className='registerLink'>Sign In</Link>
    </div>
  )
}

export default ForgotPassword
