import React from 'react'
import NavBar from './NavBar'

function AddWords() {
  return (
    <div className='pageContainer'>
        <NavBar back={true}/>
        <header>
          <p className="pageHeader">
            Anade Palabras
          </p>
        </header>
    </div>
  )
}

export default AddWords
