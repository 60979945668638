import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAuth } from "firebase/auth";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { toast } from 'react-toastify';
import { db } from '../firebase.config';
import { v4 as uuidv4 } from 'uuid';
import NavBar from '../components/NavBar';
import WordForm from '../components/WordForm';
import Button from '../components/Button';
import Header from '../components/Header';
import { UserContext } from '../context/UserContext';
import setWordList from '../utils/setWordList';

function Main() {
  const [, dispatch] = useContext(UserContext)
  const [fullFormShown, setFullFormShown] = useState(false)
  const [isAddingWord, setIsAddingWord] = useState(false)
  const initialFormState = { baseWord: '', wordDefinition: '', wordExample: '' }
  const [formData, setFormData] = useState(initialFormState)

  const navigate = useNavigate()

  const { baseWord, wordDefinition, wordExample } = formData;
  const readyToSubmit = baseWord.trim().length > 0 && wordDefinition.trim().length > 0 && wordExample.trim().length > 0

  const toastId = 'single-toast';

  const auth = getAuth();
  const user = auth.currentUser;

  const { displayName } = user

  function inputChange(e) {
    setFormData(prev => ({...prev, [e.target.id]: e.target.value}))
  }

  function openFullForm() {
    if (!fullFormShown) {
      setFullFormShown(true)
    }
  }

  function closeFullForm() {
      setFullFormShown(false)
  }

  useEffect(() => {
    window.addEventListener('click', e => {
      const isForm = !!e.target.closest('.addWordForm')
      const isSubmitBtn = !!e.target.closest('.addWordBar')
      if (fullFormShown && !(isForm || isSubmitBtn)) { 
        setFullFormShown(false)
       }
    })
  }, [fullFormShown])

  async function submitForm(e) {
    e.preventDefault()
    if (isAddingWord) { return }
    setIsAddingWord(true)
    const userPrivateWordListsRef = doc(db, "privateWordLists", auth.currentUser.uid);
    const newWord = {
      baseWord: formData.baseWord.trim().toLowerCase(),
      wordDefinition: formData.wordDefinition.trim(),
      wordExample: formData.wordExample.trim()
    }
    try {
      await toast.promise(
        updateDoc(userPrivateWordListsRef, { wordList: arrayUnion({...newWord, uid: uuidv4()}) }),
        {
          pending: 'Adding a word',
          success: 'Word added👌',
        }
        )
        setWordList(dispatch) 
        setIsAddingWord(false)
    } catch (error) {
      toast.error(error.code)
      return console.log(error.code)
    }

    setFullFormShown(false)
    setFormData(initialFormState)
  }

  return (
    <div className='pageContainer'>
      <NavBar  profile={true}/>
      <Header text={`Hola ${displayName}!`} />
      {
        fullFormShown ? 
        <WordForm 
          openFullForm={openFullForm}
          closeFullForm={closeFullForm}
          submitHandler={submitForm}
          inputHandler={inputChange}
          baseWord={baseWord}
          wordDefinition={wordDefinition}
          wordExample={wordExample}
          readyToSubmit={readyToSubmit}
        />
        :
        <form className="addWordForm" id='addWordForm' onSubmit={submitForm}>
          <input 
            className='baseWordInput' 
            placeholder='Añade una palabra' 
            value={baseWord} 
            onFocus={openFullForm}
          />
        </form>
      }
      { !fullFormShown && 
      <>
        <Button clickHandler={() => navigate('/practice')} text={'Practice'} />
        <Button clickHandler={() => navigate('/word-list')} text={'Wordlist'} btnColor={'--grey-color'} />
      </>
      }
    </div>
    
  )
}

export default Main
