import { useState, useRef, useEffect, useContext } from 'react'
import NavBar from '../components/NavBar'
import { getAuth, updateProfile } from "firebase/auth";
import { toast } from 'react-toastify';
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from '../firebase.config';
import { UserContext } from '../context/UserContext';
import Header from '../components/Header';

function Profile() {
  const auth = getAuth()
  console.log('Profile')
  const [state, dispatch] = useContext(UserContext)
  console.log('state', state)
  const [displayName, setDisplayName] = useState(state.displayName)
  const [changingName, setChangingName] = useState(false)
  const [wordsPerPractice, setWordsPerPractice] = useState(state.wordsPerPractice)
  const [changingWordsPerPractice, setChangingWordsPerPractice] = useState(false)

  const nameInputRef = useRef(null);
  const numberInputRef = useRef(null);
  function enableNameChangeInput() {
    if (changingWordsPerPractice) { setChangingWordsPerPractice(false) }
    setChangingName(true)
  }
  function enableNumberChangeInput() {
    if (changingName) { setChangingName(false) }
    setChangingWordsPerPractice(true)
  }
  useEffect(() => {
    if (changingName) { nameInputRef.current.focus() }
    if (changingWordsPerPractice) { numberInputRef.current.focus() }
  }, [ changingName, changingWordsPerPractice])

  async function changeNumber(e) {
    e.preventDefault()
    const washingtonRef = doc(db, "users", auth.currentUser.uid);
    const wordsPerPracticeNum = Number(wordsPerPractice)
    if (wordsPerPracticeNum < 1 || isNaN(wordsPerPracticeNum)) { 
      return toast.error("Please provide a valid number > 0.")
    }
    try {
      await toast.promise(
        updateDoc(washingtonRef, { wordsPerPractice:  wordsPerPracticeNum}),
        {
          pending: 'Changing wordsPerPractice!',
          success: 'wordsPerPractice changed 👌',
        }
        ) 
        dispatch({
          type: 'SET_WPP',
          payload: {
            wordsPerPractice:  wordsPerPracticeNum
          }
        })
    } catch (error) {
      toast.error(error.code)
      console.log(error.code)
    }

    
    setChangingWordsPerPractice(prev => !prev)
  }
  async function changeName(e) {
    e.preventDefault()
    if (displayName.trim().length === 0) { return toast.error("Name can't be empty")}
    try {
      await toast.promise(
        updateProfile(auth.currentUser, { displayName:  displayName.trim() }),
        {
          pending: 'Changing name!',
          success: 'Name changed 👌',
        }
        ) 
      dispatch({
          type: 'SET_DISPLAYNAME',
          payload: {
            displayName:  displayName.trim()
          }
        })
    } catch (error) {
      toast.error(error.code)
      console.log(error.code)
    }
    setChangingName(false)
  }
  return (
    <div className='pageContainer'>
      <NavBar back={true} logout={true}/>
      <Header text="Mi Perfil!" />
      <div className="profileInput">
        <div className="inputLabel">displayName</div>
        <div className="profileDisplayName">
          <form onSubmit={changeName}>
            <input
            ref={nameInputRef}
            type='text'
            className="profileDisplayNameText"
            value={displayName}
            autoCorrect='off'
            onChange={e => setDisplayName(e.target.value)}
            disabled={!changingName}/>
          </form>
          {changingName ? <div className="profileDisplayNameBtn" onClick={changeName}><strong>save</strong></div> : <div className="profileDisplayNameBtn" onClick={enableNameChangeInput}>change</div> }
        </div>
      </div>
      <div className="profileInput">
        <div className="inputLabel">WordsPerPractice</div>
        <div className="profileDisplayName">
          <form onSubmit={changeNumber}>
            <input
            type='number'
            inputMode="numeric"
            min='1'
            max='100'
            ref={numberInputRef}
            className="profileDisplayNameText"
            value={wordsPerPractice}
            onChange={e => setWordsPerPractice(e.target.value)}
            disabled={!changingWordsPerPractice}/>
          </form>
          {changingWordsPerPractice ? <div className="profileDisplayNameBtn" onClick={changeNumber}><strong>save</strong></div> : <div className="profileDisplayNameBtn" onClick={enableNumberChangeInput}>change</div> }
        </div>
      </div>
    </div>
  )
}

export default Profile
