import React from 'react'

function Button({clickHandler, isDisabled, text, btnColor = '--main-color'}) {
    const style = {
        filter: isDisabled ? 'opacity(0.5)' : '',
        backgroundColor: `var(${btnColor})`
    }
  return (
     <div className='btn' onClick={clickHandler} style={style}>
        {text}
    </div>
  )
}

export default Button
