import { useNavigate } from 'react-router-dom'
import { getAuth, signInAnonymously, updateProfile } from "firebase/auth";
import { toast } from 'react-toastify';
import anonymusIcon from '../assets/svg/anonymusIcon.svg'
import getCuteDisplayName from '../utils/getCuteDisplayName'
import { doc, setDoc, serverTimestamp } from "firebase/firestore"; 
import { db } from '../firebase.config'
import defaultWords from '../utils/defaultWords';

function Anonymus() {
  const navigate = useNavigate()

  async function onAnonymusClick() {
    try {
      const auth = getAuth()
      await signInAnonymously(auth)
      await updateProfile(auth.currentUser, { displayName: getCuteDisplayName()})
      navigate('/')
      await setDoc(doc(db, "users", auth.currentUser.uid), { 
        timestamp: serverTimestamp(),
        wordsPerPractice: 5,
        isAnonymous: true
      });
      const usersWordListRef = doc(db, "privateWordLists", auth.currentUser.uid)
      await setDoc(usersWordListRef, { wordList: defaultWords });
    } catch (err) {
      toast.error(err.code)
    }
  }

  return (
    <div className='socialLogin'>
      <p>Sign in anonymusly</p>
      <button className='socialIconDiv' onClick={onAnonymusClick}>
        <img className='socialIconImg' src={anonymusIcon} alt='Anonymus icon' />
      </button>
    </div>
  )
}

export default Anonymus

