import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import ProtectedRoute from "./components/ProtectedRoute";
import Main from "./pages/Main";
import Profile from "./pages/Profile";
import WordList from "./pages/WordList";
import Practice from "./pages/Practice";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import WellDone from "./pages/WellDone";
import ForgotPassword from "./pages/ForgotPassword";
import NotFound from "./pages/NotFound";

const router = createBrowserRouter([
  {
    path: '/',
    element: <ProtectedRoute />,
    children: [
      {
        path: '/',
        element: <Main />,
      },
      {
        path: '/profile',
        element: <Profile />,
      },
      {
        path: '/word-list',
        element: <WordList />,
      },
      {
        path: '/practice',
        element: <Practice />,
      },
      {
        path: '/well-done/:numWords',
        element: <WellDone />,
      }
    ]
  },
  {
    path: '/sign-in',
    element: <SignIn />
  },
  {
    path: '/sign-up',
    element: <SignUp />
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />
  },
  {
    path: '/*',
    element: <NotFound />
  }
  
])

function App() {
  return (
    <>
      <RouterProvider router={router}/>
      <ToastContainer autoClose={3000}/>
    </>
  );
}

export default App;
