import React from 'react'
import useAuth from '../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import Spinner from '../components/Spinner'
import Header from '../components/Header';

function NotFound() {
    const { isLoggedIn, isLoading } = useAuth()
    const navigate = useNavigate()

    const HomePage = (
         <>
            <p className='pageSubheading'>Get back to the main scren!</p>
            <div className='practiceBar' onClick={() => navigate('/')}>
                <button className='practiceButton'>
                    <span className='practiceText'>Home</span>
                </button>
            </div>
        </>
    )

    const SignInPage = (
        <>
            <p className='pageSubheading'>And you are not logged in.</p>
            <div className='practiceBar' onClick={() => navigate('/sign-in')}>
                <button className='practiceButton'>
                    <span className='practiceText'>Sing In</span>
                </button>
            </div>
        </>
    )
    const callToAction = isLoggedIn ? HomePage : SignInPage;

        if (isLoading) { return <Spinner />}
  return (
    <div className='pageContainer'>
        <Header text="Ay papa, page not found!" />
        {callToAction}
    </div>
    )
}

export default NotFound
