import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAuth } from "firebase/auth";
import { doc, getDoc, setDoc, arrayRemove, updateDoc } from "firebase/firestore";
import { toast } from 'react-toastify';
import { db } from '../firebase.config';
import getSpanishNumberWord from '../utils/getSpanishNumberWord';
import Spinner from '../components/Spinner';
import WordListItem from '../components/WordListItem';
import NavBar from '../components/NavBar';
import AddWords from '../components/AddWords';
import setContextWordList from '../utils/setWordList';
import { UserContext } from '../context/UserContext';
import Header from '../components/Header';
import WordForm from '../components/WordForm';

function WordList() {
  const [, dispatch] = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [isModifying, setIsModifying] = useState(false)
  const [wordList, setWordList] = useState([])
  const [fullFormShown, setFullFormShown] = useState(false)
  const initialFormState = { baseWord: '', wordDefinition: '', wordExample: '' }
  const [formData, setFormData] = useState(initialFormState)
  const prevWord = {...formData}
  const navigate = useNavigate()

  const { baseWord, wordDefinition, wordExample } = formData;
  const readyToSubmit = baseWord.trim().length > 0 && wordDefinition.trim().length > 0 && wordExample.trim().length > 0

  const toastId = 'single-toast';

  const auth = getAuth();

  console.log('wordList', wordList)
  const displayNumber = getSpanishNumberWord(wordList ? wordList.length : 1)

  function inputChange(e) {
    setFormData(prev => ({...prev, [e.target.id]: e.target.value}))
  }

  function openFullForm() {
    if (!fullFormShown) {
      setFullFormShown(true)
    }
  }

  function closeFullForm() {
      setFullFormShown(false)
  }

  useEffect(() => {
    window.addEventListener('click', e => {
      const isForm = !!e.target.closest('.addWordForm')
      const isSubmitBtn = !!e.target.closest('.addWordBar')
      const isEditBtn = !!e.target.closest('.editBtn')
      if (fullFormShown && !(isForm || isSubmitBtn || isEditBtn)) { 
        setFullFormShown(false)
       }
    })
  }, [fullFormShown])

  useEffect(() => {
    getPrivateWordLists()

    async function getPrivateWordLists() {
      const docRef = doc(db, "privateWordLists", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      console.log("Before everything is fucked")
      if (docSnap.exists()) {
        console.log("Before everything is fucked")
        console.log('docSnap.data().wordList', docSnap.data().wordList)
        setWordList(docSnap.data().wordList);
      } else {
        console.log("No such document!");
      }
      setIsLoading(false)
    }
  }, [isModifying, auth.currentUser.uid])

  useEffect(() => {
    setIsModifying(false)
  }, [wordList])

  async function submitForm(e) {
    e.preventDefault()
    if (isModifying || !readyToSubmit) { return }
    setIsModifying(true)
    const userPrivateWordListsRef = doc(db, "privateWordLists", auth.currentUser.uid);
    const updatedArray = [...wordList]
    const updatedWord = updatedArray.findIndex(el => el.uid === prevWord.uid)
    updatedArray[updatedWord] = {...formData}
    try {
      await toast.promise(
        setDoc(userPrivateWordListsRef, { wordList: updatedArray }),
        {
          pending: 'Editing a word',
          success: 'Word edited',
        }
        ) 
      setContextWordList(dispatch)
    } catch (error) {
      return toast.error(error.code)
    }
    setFullFormShown(false)
    setFormData(initialFormState)
  }

  async function deleteWord(wordObj) {
    if (isModifying) { return }
    setIsModifying(true)
    const userPrivateWordListsRef = doc(db, "privateWordLists", auth.currentUser.uid);
    try {
      await toast.promise(
        updateDoc(userPrivateWordListsRef, { wordList: arrayRemove(wordObj) }),
        {
          pending: 'Deleting a word',
          success: 'Word deleted',
        }
        ) 
      setContextWordList(dispatch)
    } catch (error) {
      return toast.error(error.code)
    }
  }

  function editWord(wordObj) {
    setFormData(wordObj)
    setFullFormShown(true)
  }

  const displayedList = wordList.map(el => <WordListItem key={el.uid} baseWord={el.baseWord} uid={el.uid} deleteHandler={() => deleteWord(el)} editHandler={() => editWord(el)} />)
  
  if (isLoading) { return <Spinner />}
  if (wordList.length === 0) { return <AddWords />}
  return (
    <div className='pageContainer'>
      <NavBar back={true}/>
      <Header text={ fullFormShown ? "Edit word" : `${displayNumber} Palabra${wordList.length === 1 ? '' : 's'}` } /> 
      
      {fullFormShown ?
      <WordForm 
        openFullForm={openFullForm}
        closeFullForm={closeFullForm}
        submitHandler={submitForm}
        inputHandler={inputChange}
        baseWord={baseWord}
        wordDefinition={wordDefinition}
        wordExample={wordExample}
        readyToSubmit={readyToSubmit}
      />
      :
      <ul className='wordListContainer'>{displayedList}</ul>
      }
    </div>
    
  )
}

export default WordList
