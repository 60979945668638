import React from 'react'
import { FaXmark } from 'react-icons/fa6';
import Button from '../components/Button';

function WordForm({ openFullForm, closeFullForm, submitHandler, inputHandler, baseWord, wordDefinition, wordExample, readyToSubmit }) {

  return (
    <form className="addWordForm" id='addWordForm' onSubmit={submitHandler}>
        <input 
            required
            type="text" 
            id="baseWord"
            className='baseWordInput' 
            placeholder='Añade una palabra' 
            value={baseWord} 
            onChange={inputHandler} 
            onFocus={openFullForm}
            autoComplete="off"
        />

        <input 
            required
            type="text" 
            id="wordDefinition" 
            className='baseWordInput wordDefinitionInput' 
            placeholder="Definición" 
            value={wordDefinition} 
            onChange={inputHandler} 
            autoComplete="off" 
        />

        <input 
            required
            type="text" 
            id="wordExample" 
            className='baseWordInput wordExampleInput' 
            placeholder="Ejemplo" 
            value={wordExample} 
            onChange={inputHandler} 
            autoComplete="off" 
        />

        <div className='actionBtn closeForm' onClick={closeFullForm}><FaXmark />
        </div>

        <Button clickHandler={submitHandler} text={'Submit Word'} isDisabled={!readyToSubmit}/>
    </form> 
  )
}

export default WordForm
