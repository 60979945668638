import { useState } from 'react'

function BluredElem({ type = 'baseWord', text}) {
    const [isBlured, setIsBlured] = useState(true)
    const hinContainerStyle = {
        textAlign: type === 'baseWord' ? 'center' : '',
    }
    const blurStyle = {
        filter: isBlured ? 'blur(5px)' : '',
    }
    function showHint() {
        setIsBlured(false)
        setTimeout(() => setIsBlured(true), 3000)
    }
    return (
        <div className="hintContainer" style={hinContainerStyle} onClick={showHint}>
            { type === 'example' && <strong>ejemplo: </strong> }
            <span style={blurStyle}>
                {text}
            </span>
        </div>
    ) 
}

export default BluredElem
