const defaultWords = [
    {
        "baseWord": "casa",
        "wordDefinition": "Edificio donde vive una persona o una familia.",
        "wordExample": "Mi abuelo vive en una casa grande.",
        uid: 1
    },
    {
        "baseWord": "comer",
        "wordDefinition": "Ingerir alimentos a través de la boca.",
        "wordExample": "Me gusta comer frutas frescas todos los días.",
        uid: 2
    },
    {
        "baseWord": "luz",
        "wordDefinition": "Agente físico que permite ver las cosas.",
        "wordExample": "Necesito encender la luz para leer el libro.",
        uid: 3
    },
    {
        "baseWord": "amigo",
        "wordDefinition": "Persona con la que se tiene una relación cercana y de confianza.",
        "wordExample": "Mi mejor amigo siempre está dispuesto a ayudarme.",
        uid: 4
    },
    {
        "baseWord": "viajar",
        "wordDefinition": "Desplazarse de un lugar a otro, generalmente por placer o negocio.",
        "wordExample": "Este verano planeamos viajar a la playa.",
        uid: 5
    }
]

export default defaultWords