import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import getSpanishNumberWord from '../utils/getSpanishNumberWord'
import Button from '../components/Button';
import Header from '../components/Header';

function WellDone() {
    const { numWords } = useParams()
    const displayedNum = getSpanishNumberWord(numWords)
    const navigate = useNavigate()
  return (
      <div className='pageContainer'>
        <Header text={`Muy bien ${ displayedNum } palabars repetidas!`} /> 
        <Header text="Quieres mas?" /> 
        <Button clickHandler={() => navigate('/practice')} text={'Practice'} />
        <Link to='/' className='backToMainLink '>
          No
        </Link>
    </div>
  )
}

export default WellDone
