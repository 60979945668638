import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaUserLarge } from "react-icons/fa6";
import { getAuth } from "firebase/auth";
import { toast } from 'react-toastify';

function NavBar({ back, profile, logout }) {
    
    const navigate = useNavigate()
    const auth = getAuth();
    const isAnon = auth.currentUser && auth.currentUser.isAnonymous
    
    async function signOutHandler() {
        try {
            await auth.signOut()
            navigate('/sign-in')
        } catch (error) {
            toast.error(error.code)
        }
    }

    const signOut = <div className="actionBtn logout" onClick={signOutHandler}>Logout</div>

    const signUp = <div className="actionBtn logout" onClick={() => navigate('/sign-up')}>Sign Up</div>


  return (
    <nav className="navBar">
        {back && <div className="actionBtn return"
            onClick={() => navigate('/')}
            >
          <FaArrowLeftLong />
        </div>}
        {profile && <div className="actionBtn profile" onClick={() => navigate('/profile')}>
          <FaUserLarge />
        </div>}
        {logout ?( isAnon ? signUp : signOut) : <></>}
      </nav>
  )
}

export default NavBar
