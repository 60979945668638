import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { toast } from 'react-toastify';
import Anonymus from '../components/Anonymus';
import Header from '../components/Header';
import AuthForm from '../components/AuthForm';

function SignIn() {
  const [passwordShown, setPasswordShown] = useState(false)
  const [formData, setFormData] = useState({email: '', password: ''})
  const { email, password } = formData;
  
  const navigate = useNavigate()

  const validEmail = !!email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const validPassword = password.trim().length >= 6;

  const submitBtnEnabled = validEmail && validPassword;

  const toastId = 'single-toast';

  function inputChangeHandler(e) {
    setFormData(prev => ({...prev, [e.target.id]: e.target.value}))
  }

  function togglePasswordVisibility() {
    setPasswordShown(prev => !prev)
  }

  async function formSubmitHandler(e) {
    e.preventDefault()
    if (!submitBtnEnabled) { return }
    const auth = getAuth()

    try {
      await toast.promise(
        signInWithEmailAndPassword(auth, email.trim(), password.trim()),
        {
          pending: 'Signing In!',
          success: 'Signed in 👌',
        }
        ) 
      navigate('/')
    } catch (err) {
        if (err.code === 'auth/wrong-password') {
          return toast.error("Wrong password!", { toastId }) 
        }
        if (err.code === 'auth/user-not-found') {
          return toast.error("User not found", { toastId }) 
        }
        return toast.error(err.code , { toastId }) 
      }
  }
  
  return (
    <div className='pageContainer'>
      <Header text="Empezar" />
      <AuthForm 
        isSignIn={true}
        submitHandler={formSubmitHandler}
        inputHandler={inputChangeHandler}
        email={email}
        password={password}
        passwordShown={passwordShown}
        passwordVisibilityHandler={togglePasswordVisibility}
        isDisabled={!submitBtnEnabled}
      />
      <Link to='/sign-up' className='registerLink'>
        Sign up instead
      </Link>
      <Anonymus />
    </div>
  )
}

export default SignIn
