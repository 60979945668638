import { Outlet , Navigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth';
import Spinner from './Spinner';
import { useEffect, useState, useReducer } from 'react';
import { UserContext } from '../context/UserContext';
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from '../firebase.config';
import { getAuth } from "firebase/auth";
import reducer from '../context/reducer';

const userModel = {
    displayName: 'Osito',
    wordsPerPractice: 5,
    wordsForPractice: [],
}

function ProtectedRoute() {
  console.log('ProtectedRoute')
  const { isLoggedIn, isLoading, userDetails } = useAuth()
  const [ userDataLoaded, setUserDataLoaded ] = useState(false)
  const [ state, dispatch ] = useReducer(reducer, userModel)

  if (userDetails && !userDataLoaded) { 
    dispatch({
        type: 'SET_USER',
        payload: {
          user:  userDetails
        }
      })
    setUserDataLoaded(true)
  }

  if (isLoading) { return <Spinner /> }

  if (isLoggedIn) { 
    return (
    <UserContext.Provider value={[state, dispatch]}>
      <Outlet />
    </UserContext.Provider>
    )
  }

  return <Navigate to='/sign-in' />
}

export default ProtectedRoute
