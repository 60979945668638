import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, createUserWithEmailAndPassword, updateProfile, EmailAuthProvider, linkWithCredential } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore"; 
import { db } from '../firebase.config'
import { toast } from 'react-toastify';
import getCuteDisplayName from '../utils/getCuteDisplayName';
import defaultWords from '../utils/defaultWords';
import Header from '../components/Header';
import AuthForm from '../components/AuthForm';

function SignUp() {
  const [passwordShown, setPasswordShown] = useState(false)
  const [formData, setFormData] = useState({email: '', password: ''})
  const navigate = useNavigate()

  const { email, password } = formData;

  const validEmail = !!email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const validPassword = password.trim().length >= 6;
  const submitBtnEnabled = validEmail && validPassword;

  const toastId = 'single-toast';

  function inputChangeHandler(e) {
    setFormData(prev => ({...prev, [e.target.id]: e.target.value}))
  }

  function togglePasswordVisibility() {
    setPasswordShown(prev => !prev)
  }

  async function formSubmitHandler(e) {
    e.preventDefault()
    if (!submitBtnEnabled) { return }
    const auth = getAuth()

    if (auth.currentUser && auth.currentUser.isAnonymous) {
      const credential = EmailAuthProvider.credential(email, password);
      try {
        await toast.promise(
        linkWithCredential(auth.currentUser, credential),
        {
          pending: 'Creating account!',
          success: 'Account creatid 👌',
        }
        ) 
      return navigate('/')
      } catch (error) {
        if (error.code === 'auth/email-already-in-use') { 
        toast.error("El correo electrónico ya está en uso.", { toastId }) 
      } else { toast.error(error.code)  }
      return console.log(error.code)
      }
    }

    try {
      await toast.promise(
        createUserWithEmailAndPassword(auth, email.trim(), password.trim()),
        {
          pending: 'Creating a user!',
          success: 'User created 👌',
        }
        ) 
      await updateProfile(auth.currentUser, { displayName: getCuteDisplayName()})
      await setDoc(doc(db, "users", auth.currentUser.uid), { 
        email, 
        wordsPerPractice: 5,
        timestamp: serverTimestamp() 
      });
      const usersWordListRef = doc(db, "privateWordLists", auth.currentUser.uid)
      await setDoc(usersWordListRef, { wordList: defaultWords });
    } catch (err) {
      if (err.code === 'auth/email-already-in-use') { 
        toast.error("El correo electrónico ya está en uso.", { toastId }) 
      } else { toast.error(err.code, { toastId }) }
      return console.log(err.code)
    }
    navigate('/')
  }
  
  return (
    <div className='pageContainer'>
      <Header text="Crear cuenta" /> 
      <AuthForm 
          submitHandler={formSubmitHandler}
          inputHandler={inputChangeHandler}
          email={email}
          password={password}
          passwordShown={passwordShown}
          passwordVisibilityHandler={togglePasswordVisibility}
          isDisabled={!submitBtnEnabled}
      />
      <Link to='/sign-in' className='registerLink'>
        Sign In
      </Link>
    </div>
  )
}

export default SignUp
