import React from 'react'
import { FaXmark, FaFeather } from "react-icons/fa6";

function WordListItem(props) {
    const { baseWord, uid, deleteHandler, editHandler } = props
  return (
    <li className="wordListItem" data-uid={uid}>
        <div className="actionBtn" onClick={deleteHandler}>
            <FaXmark/>
        </div>
        {baseWord}
        <div className="actionBtn editBtn" onClick={editHandler}>
            <FaFeather />
        </div>
    </li>
  )
}

export default WordListItem

