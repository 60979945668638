import React from 'react'
import { Link } from 'react-router-dom'
import visibilityIcon from '../assets/svg/visibilityIcon.svg'
import Button from './Button'

function AuthForm({ isSignIn, submitHandler, inputHandler, email, password, passwordShown, passwordVisibilityHandler, isDisabled }) {
  return (
    <form onSubmit={submitHandler}>
        <input 
          required
          type="email" 
          id='email' 
          className='emailInput' 
          placeholder='Correo electrónico' 
          value={email} 
          onChange={inputHandler} 
        />
        <div className="passwordInputDiv">
          <input 
            required 
            type={ passwordShown ? 'text' : 'password'} 
            id='password' 
            className='passwordInput' 
            placeholder='Contraseña' 
            value={password} 
            onChange={inputHandler} 
            minLength="6" 
          />
          <img src={visibilityIcon} alt="Show password button" className='showPassword' onClick={passwordVisibilityHandler}/>
        </div>

        {isSignIn && <Link to='/forgot-password' className='forgotPasswordLink'>
          Forgot password
        </Link>}
        <Button clickHandler={submitHandler} text={`Sign ${isSignIn ? "In" : 'Up'}`} isDisabled={isDisabled}/>
      </form>
  )
}

export default AuthForm
