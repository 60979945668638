import { useState, useEffect, useRef, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { toast } from 'react-toastify';
import { db } from '../firebase.config';
import getSpanishNumberWord from '../utils/getSpanishNumberWord';
import Spinner from '../components/Spinner';
import shuffleArray from '../utils/shuffleArray';
import setWordList from '../utils/setWordList';
import NavBar from '../components/NavBar';
import AddWords from '../components/AddWords';
import BluredElem from '../components/BluredElem';
import Button from '../components/Button';
import { UserContext } from '../context/UserContext';

function Practice() {
  const [state, dispatch] = useContext(UserContext)
  const [wordInput, setWordInput] = useState('')
  const [currentWordIndex, setCurrentWordIndex] = useState(0)
  const [exampleHintShown, setExampleHintShown] = useState(false)
  const [wordHintShown, setWordHintShown] = useState(false)

  const wordList = state.wordList.slice(0, Number(state.wordsPerPractice))
  const wordsRepited = wordList.length
  const navigate = useNavigate()
  const pageContainer = useRef(null)
  console.log('currentWordIndex', currentWordIndex)
  console.log('wordsRepited', wordsRepited)

  if (wordsRepited === 0) {return <AddWords />}

  if (currentWordIndex === wordsRepited) {
    setWordList(dispatch)
    return navigate(`/well-done/${wordsRepited}`)
  }
  
  const currentWord = wordList[currentWordIndex]
  const { baseWord, wordDefinition, wordExample } = currentWord;

  const toastId = 'single-toast';
  async function submitForm(e) {
    e.preventDefault();
    if (wordInput.trim().toLowerCase() === currentWord.baseWord.toLowerCase()) {
      setCurrentWordIndex(prev => prev + 1)
      setWordInput('')
    } else {
      toast.error("Words don't match!")
    }
  }

  function wordInputChange(e) {
    setWordInput(e.target.value)
  }

  const wordIsCorrect = wordInput.trim().toLowerCase() === currentWord.baseWord
  const wordInputClass = wordIsCorrect ? 'correct' :
  wordInput.toLowerCase() !== currentWord.baseWord.slice(0, wordInput.length) ? 'incorrect' : ''

  if (wordsRepited === 0) { return <AddWords /> }

  return (
    <div className='pageContainer' ref={pageContainer}>
      <NavBar back={true}/>
      <div className='practiceWordContainer'>
        <div className="practiceWordDefinition">
          <strong>definición:</strong> {wordDefinition}
        </div>
        <BluredElem type="example" text={wordExample}/>
        <BluredElem text={baseWord}/>
        <form className={`practiceForm ${wordInputClass}`} onSubmit={submitForm}>
          <input className={`practiceBaseWordInput`} value={wordInput} onChange={wordInputChange}/>
        </form>
        <Button clickHandler={submitForm} isDisabled={!wordIsCorrect} text={'Siguente'} />
      </div>
    </div>
    
  )
}

export default Practice
