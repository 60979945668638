export default function reducer(state, action) {
  switch(action.type) {
    case 'SET_WPP':
        return { ...state, wordsPerPractice: action.payload.wordsPerPractice }
    case 'SET_WORDLIST':
        return { ...state, wordList: action.payload.wordList }
    case 'SET_DISPLAYNAME':
        return { ...state, displayName: action.payload.displayName }
    case 'SET_USER':
        return action.payload.user
    default: 
        return state   
  }
}

