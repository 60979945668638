import React from 'react'

function Header({ text }) {
  return (
    <header>
        <p className="pageHeader">
            { text }
        </p>
    </header>
  )
}

export default Header
