import shuffleArray from "./shuffleArray";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.config";

export default async function setWordList(dispatch) {
    const auth = getAuth()
    const wordListRef = doc(db, "privateWordLists", auth.currentUser.uid);
    const wordListSnap = await getDoc(wordListRef);
    const { wordList } = wordListSnap.data()
    shuffleArray(wordList)
    dispatch({
          type: 'SET_WORDLIST',
          payload: {
            wordList:  wordList
          }
        })
  }